import $ from 'jquery';
import 'jquery-ui/themes/base/draggable.css';
import 'jquery-ui/themes/base/sortable.css';
import 'jquery-ui/ui/widgets/draggable';
import 'jquery-ui/ui/widgets/sortable';

// Make sure jQuery is exposed outside of Webpack
window.$ = $;
global.$ = $;
window.jQuery = $;
global.jQuery = $;
